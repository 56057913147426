/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'

import Header from 'components/Header'
import 'styles/main.scss'

const Layout = ({ children, light, absolute, showContactUsLink, dark }) => {
  const [isEndOfPage, setIsEndOfPage] = useState(false)

  function handleWaypointEnter() {
    setIsEndOfPage(true)
  }

  function handleWaypointLeave() {
    setIsEndOfPage(false)
  }

  return (
    <>
      <Header
        showContactUsLink={showContactUsLink}
        light={light}
        absolute={absolute}
        dark={dark}
        isEndOfPage={isEndOfPage}
      />
      <div>
        <main>{children}</main>
        <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />
      </div>
    </>
  )
}

Layout.defaultProps = {
  light: false,
  absolute: false,
  dark: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
  dark: PropTypes.bool,
  absolute: PropTypes.bool,
  showContactUsLink: PropTypes.bool,
}

export default Layout
