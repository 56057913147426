import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Waypoint } from 'react-waypoint'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { PbjLogoWhite, HamburgerMenu, Mail, CloseIcon } from 'components/Icons'
import { mailtoContact } from './constants'

const Header = ({ light, absolute, showContactUsLink, dark, isEndOfPage }) => {
  const [sticky, setSticky] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    if (isMenuOpen) {
      disableScroll()
    } else {
      enableScroll()
    }
  }, [isMenuOpen])

  function handleWaypointEnter() {
    setSticky(false)
  }

  function handleWaypointLeave() {
    setSticky(true)
  }

  function disableScroll() {
    document.body.classList.add('stop-scrolling')
  }

  function enableScroll() {
    document.body.classList.remove('stop-scrolling')
  }

  return (
    <>
      {('showContactUsLink', showContactUsLink)}
      <header
        className={cx('header', {
          light,
          absolute,
          sticky,
          dark,
        })}
      >
        <div className="navbar">
          <ul className="nav-list">
            <li className="nav-list-item">
              <Link to="/">About</Link>
            </li>
            <li className="nav-list-item">
              <Link to="/portfolio">Portfolio</Link>
            </li>
            <li>
              <Link to="/" className="logo">
                <PbjLogoWhite />
              </Link>
            </li>
            <li className="nav-list-item">
              <Link to="/team">Team</Link>
            </li>
            <li className="nav-list-item">
              <a
                className={cx('header-contact-us', {
                  show: showContactUsLink,
                })}
                href={mailtoContact}
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact
              </a>
            </li>
            {/* </div> */}
          </ul>
        </div>
        <div className="navbar --mobile">
          <Link to="/" className="logo">
            <PbjLogoWhite />
          </Link>
          <div>
            <a
              className="header-mail"
              href={mailtoContact}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Mail />
            </a>
            <button
              type="button"
              onClick={toggleMenu}
              className="hamburger-button"
            >
              {isMenuOpen ? <CloseIcon /> : <HamburgerMenu />}
            </button>
            <div
              className={cx('hamburger-menu', {
                '--open': isMenuOpen,
              })}
            >
              <ul className="hamburger_menu-nav-list">
                <li className="hamburger_menu-nav-list-item">
                  <Link to="/">About</Link>
                </li>
                <li className="hamburger_menu-nav-list-item">
                  <Link to="/portfolio">Portfolio</Link>
                </li>
                <li className="hamburger_menu-nav-list-item">
                  <Link to="/team">Team</Link>
                </li>
                <li className="hamburger_menu-nav-list-item">
                  <a
                    className={cx('header-contact-us', {
                      show: showContactUsLink,
                    })}
                    href="mailto:hello@pbjapps.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={cx('hamburger-overlay', {
            '--open': isMenuOpen,
          })}
        />
      </header>
      <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />
      <a
        className={cx('bottom-contant-fixed', {
          dark,
          '--show': sticky,
          '--end': isEndOfPage,
        })}
        href={mailtoContact}
        target="_blank"
        rel="noopener noreferrer"
      >
        Say Hello
      </a>
    </>
  )
}

Header.propTypes = {
  light: PropTypes.bool,
  absolute: PropTypes.bool,
  showContactUsLink: PropTypes.bool,
  dark: PropTypes.bool,
  isEndOfPage: PropTypes.bool,
}

export default Header
