import React from 'react'
import PropTypes, { object } from 'prop-types'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({
  className,
  children,
  image,
  imageList,
  isMultiBackground,
}) => {
  const getFluidImage = () => {
    if (isMultiBackground) {
      return imageList.map(({ childImageSharp }) => childImageSharp.fluid)
    }
    return image.childImageSharp.fluid
  }

  return (
    <BackgroundImage className={className} fluid={getFluidImage()}>
      {children}
    </BackgroundImage>
  )
}

BackgroundSection.propTypes = {
  image: object,
  className: PropTypes.string,
  children: PropTypes.node,
  isMultiBackground: PropTypes.bool,
  imageList: PropTypes.array,
}

export default BackgroundSection
