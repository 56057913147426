import React from 'react'
import PropTypes from 'prop-types'
import Image from 'components/Image'

const PortfolioHeader = ({
  heading,
  description,
  image1: { url: image1Url, altText: image1altText },
  image2: { url: image2Url, altText: image2altText },
  singleImage,
  containerClassName,
  image,
}) => {
  const renderImages = () => {
    if (singleImage) {
      return <Image image={image} className="portfolio-app-img single" />
    }
    return (
      <>
        <img
          src={image1Url}
          className="portfolio-app-img"
          alt={image1altText}
        />
        <img
          src={image2Url}
          className="portfolio-app-img"
          alt={image2altText}
        />
      </>
    )
  }

  return (
    <div className={containerClassName}>
      <div className="container">
        <section className="portfolio_header-wrapper">
          <h2
            className="portfolio-heading"
            data-sal="zoom-out"
            data-sal-delay="300"
            data-sal-easing="ease-out-back"
          >
            {heading}
          </h2>
          <p className="portfolio-description">{description}</p>
        </section>
        <section className="portfolio-app-wrapper">
          <div className="portfolio-app">{renderImages()}</div>
        </section>
      </div>
    </div>
  )
}

PortfolioHeader.defaultProps = {
  image1: {},
  image2: {},
}

PortfolioHeader.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  image1: PropTypes.object,
  image2: PropTypes.object,
  image: PropTypes.object,
  containerClassName: PropTypes.string,
  singleImage: PropTypes.bool,
}

export default PortfolioHeader
