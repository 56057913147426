import React from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'gatsby'
import cx from 'classnames'

import { mailtoContact } from 'components/Header/constants'
import getFullYear from 'utils/getFullYear'
// import { InstagramLogo, LinkedInLogo, TwitterLogo } from 'components/Icons'

const Footer = ({ light }) => (
  <footer
    className={cx('footer', {
      light,
    })}
  >
    <div className="container">
      <h2 className="footer-heading">
        <a
          className="footer-mail-link"
          href={mailtoContact}
          target="_blank"
          rel="noopener noreferrer"
        >
          Get in Touch
        </a>
      </h2>
      <div className="footer-actions">
        <ul className="footer-links">
          {/* <li className="footer-link-item">
            <Link to="/" className="logo">
              <TwitterLogo />
            </Link>
          </li>
          <li className="footer-link-item">
            <Link to="/" className="logo">
              <InstagramLogo />
            </Link>
          </li>
          <li className="footer-link-item">
            <Link to="/" className="logo">
              <LinkedInLogo />
            </Link>
          </li> */}
        </ul>
        <div>
          <p className="footer-copyright">
            {`Copyright © PBJApps, LLC ${getFullYear()}`}
          </p>
        </div>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  light: PropTypes.bool,
}

export default Footer
