import MorningIcon from 'images/spiritune/icons/morning-icon.svg'
import StudyingIcon from 'images/spiritune/icons/studying-icon.svg'
import TransportIcon from 'images/spiritune/icons/transport-icon.svg'
import RelaxingIcon from 'images/spiritune/icons/relaxing-icon.svg'
import SleepIcon from 'images/spiritune/icons/sleep-icon.svg'
import Ball from 'images/spiritune/icons/ball.svg'
import MusicalNote from 'images/spiritune/icons/musical-note.svg'
import Cube from 'images/spiritune/icons/cube.svg'
import ChatThink from 'images/spiritune/icons/chat-think.svg'
import CircleInsideHexagon from 'images/spiritune/icons/circle-inside-hexagon.svg'
import PbjLogoWhite from 'images/icons/pbj-logo-white.svg'
import HamburgerMenu from 'images/icons/hamburger-menu.svg'
import Mail from 'images/icons/mail.svg'
import CloseIcon from 'images/icons/close-icon.svg'
import InstagramLogo from 'images/icons/instagram-logo.svg'
import LinkedInLogo from 'images/icons/linkedin-logo.svg'
import TwitterLogo from 'images/icons/twitter-logo.svg'
import TeamsMap from 'images/icons/teams-map.svg'
import UsaPin from 'images/icons/usa-pin.svg'
import UkPin from 'images/icons/uk-pin.svg'
import FrancePin from 'images/icons/france-pin.svg'
import NetherlandsPin from 'images/icons/netherlands-pin.svg'
import IndiaPin from 'images/icons/india-pin.svg'

import MoeComments from 'images/moe-assist/icons/moe-comments.svg'
import MoeShare from 'images/moe-assist/icons/moe-share.svg'
import MoeDelete from 'images/moe-assist/icons/moe-delete.svg'
import MoePen from 'images/moe-assist/icons/moe-pen.svg'
import MoeInvoice from 'images/moe-assist/icons/moe-invoice.svg'

export {
  PbjLogoWhite,
  HamburgerMenu,
  Mail,
  CloseIcon,
  InstagramLogo,
  LinkedInLogo,
  TwitterLogo,
  MorningIcon,
  StudyingIcon,
  TransportIcon,
  RelaxingIcon,
  SleepIcon,
  Ball,
  MusicalNote,
  Cube,
  ChatThink,
  CircleInsideHexagon,
  TeamsMap,
  UsaPin,
  UkPin,
  FrancePin,
  NetherlandsPin,
  IndiaPin,
  MoeComments,
  MoeShare,
  MoeDelete,
  MoePen,
  MoeInvoice,
}
